import { styled } from '@mui/material/styles';

const max930 = '@media (max-width:930px)';
const max780 = '@media (max-width:780px)';

export const Section = styled('section')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '75px',
  marginTop: '50px',
  marginBottom: '100px',

  width: '100%',

  p: {
    margin: 0,
  },

  [max930]: {
    marginTop: '80px',
    gap: '50px',
  },
}));

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  paddingInline: '40px',

  gap: '50px',

  [theme.breakpoints.down('md')]: {
    paddingInline: '20px',
  },

  [max930]: {
    gap: '20px',
  },
}));

export const Title = styled('p')(({ theme }) => ({
  fontSize: '65px',
  fontHeight: '75px',
  fontWeight: 500,
  color: '#000',
  textAlign: 'center',

  [theme.breakpoints.down('md')]: {
    fontSize: '28px',
    fontWeight: 500,
  },
}));

export const Subtitle = styled('p')(({ theme }) => ({
  fontSize: '15px',
  lineHeight: '22px',
  fontWeight: 300,
  color: '#000000',
  maxWidth: '60%',

  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    maxWidth: '100%',
    margin: '0 30px !important',
  },
}));

export const FilmmakersContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '10px',
  [theme.breakpoints.down(1921)]: {
    maxWidth: '100vw',
    overflow: 'hidden',
  },
}));

export const FilmmakerCard = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  width: '466px',
  height: '432px',
}));

export const Image = styled('img')(({ theme }) => ({
  objectFit: 'cover',
  borderRadius: '50px',
  objectPosition: 'center',
}));

export const TextContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: '50px',
  left: '50px',
}));

export const Name = styled('p')(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 500,
  color: '#fff',
}));

export const Description = styled('p')(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 400,
  color: '#fff',
  paddingRight: '50px',
}));

export const Footer = styled('footer')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  gap: '30px',
  width: 'fit-content',
  paddingInline: '20px',
  marginTop: '-50px',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },

  [theme.breakpoints.down('md')]: {
    margin: '20px 30px',
    gap: '50px',
  },
}));

type FooterItemsProps = {
  indexNum: number;
};

export const FooterItem = styled('div')<FooterItemsProps>(
  ({ theme, indexNum }) => ({
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    maxWidth: '453px',

    '&:nth-of-type(1)': {
      marginTop: '-50px !important',
    },
    '&:nth-of-type(2)': {
      marginTop: '50px !important',
    },
    '&:nth-of-type(3)': {
      marginTop: '150px !important',
      marginRight: '10px',

      [max780]: {
        marginTop: '50px !important',
      },
    },

    [theme.breakpoints.down('md')]: {
      marginTop: 'none !important',
      gap: '10px',
      '&:nth-of-type(1)': {
        marginTop: '0 !important',
      },
      '&:nth-of-type(2)': {
        marginTop: '0 !important',
      },
      '&:nth-of-type(3)': {
        marginTop: '0 !important',
      },
    },
  })
);

export const FooterTitle = styled('p')(({ theme }) => ({
  width: 'fit-content',
  fontSize: '40px',
  fontWeight: 500,
  color: '#000',
  [theme.breakpoints.down('md')]: {
    fontSize: '26px',
  },
}));

export const FooterDescription = styled('p')(({ theme }) => ({
  width: 'fit-content',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 300,
  color: '#000',
  maxWidth: '80%',
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    lineHeight: '18px',
    maxWidth: '100%',
  },
}));
