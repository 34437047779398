import { promiseAllSettled } from '@bamboo/ts-utils';
import {
  Section,
  Title,
  Description,
  FilmmakerCard,
  FilmmakersContainer,
  Footer,
  Header,
  Name,
  Subtitle,
  TextContainer,
  Image,
  FooterDescription,
  FooterItem,
  FooterTitle,
} from './Filmmakers.styles';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import api from '@bamboo/core-lib/api';
import { setFilmmakers, useFilmmakers } from '@bamboo/core-lib/repo/filmmaker';
import HiddenComponent from '@bamboo/core-lib/components/HiddenComponent';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { FilmmakersCarousel } from '../best-artists/components.tsx/FilmmakerCarousel';
import type { CarouselItem } from '../../Carousel';

export const Filmmakers = () => {
  const router = useRouter();
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  const { filmmakerBySlug, filmmakersListed } = useFilmmakers();

  const filmmakes = [
    'duda-gulman',
    'fabio-meirelles',
    'leo-cavazzana',
    'carbono',
    'marcelo-ferreira',
  ];

  function handlePlansPage() {
    router.push('/plans');
  }

  useEffect(() => {
    promiseAllSettled(
      filmmakes.map((slug) =>
        api.filmmakerProfileGet({ params: { slug } }).catch((e) => {
          console.warn(e);
          throw e;
        })
      )
    ).then((res) => {
      const filmmakers = res
        .filter((r) => r.status === 'fulfilled')
        .map((r: any) => r?.value?.data?.item);
      setFilmmakers(filmmakers);
    });
  }, []);

  const filmmakersToShow = [...filmmakersListed, ...filmmakersListed].map(
    (item) => {
      return filmmakerBySlug[item];
    }
  );

  const carouselList: CarouselItem[] = filmmakersListed.map((item) => {
    return {
      image: filmmakerBySlug[item]?.avatar,
      label: (
        <div
          style={{
            position: 'absolute',
            bottom: '50px',
            left: '35px',
            color: '#fff',
          }}
        >
          <p
            style={{
              fontSize: '24px',
              lineHeight: '30px',
              fontWeight: 500,
              maxWidth: '250px',
            }}
          >
            {filmmakerBySlug[item]?.displayName}
          </p>
          <p
            style={{
              fontSize: '10px',
              lineHeight: '14px',
              maxWidth: '250px',
            }}
          >
            {filmmakerBySlug[item]?.description}
          </p>
        </div>
      ),
      name: filmmakerBySlug[item]?.displayName,
      slug: filmmakerBySlug[item]?.slug,
    } as CarouselItem;
  });

  return (
    <Section>
      <Header>
        <Title>
          Impulsionando a economia
          <br />
          criativa e o talento brasileiro
        </Title>

        <Subtitle>
          Estamos numa busca incansável pelos maiores talentos do Brasil para
          criar um acervo inédito, com olhares únicos sobre nossa cultura e
          diversidade.
        </Subtitle>
      </Header>

      <HiddenComponent hidden={!isSm}>
        <FilmmakersCarousel list={carouselList} />
      </HiddenComponent>

      <HiddenComponent hidden={isSm}>
        <FilmmakersContainer>
          {filmmakersToShow.slice(0, 5).map((item, index) => (
            <FilmmakerCard
              key={index}
              style={{
                marginTop: 30 * index,
              }}
            >
              <Image
                src={item?.avatar ?? ''}
                alt={item?.displayName ?? ''}
                width={466}
                height={432}
                style={{
                  objectFit: 'cover',
                  borderRadius: '50px',
                  objectPosition: 'center',
                }}
              />
              {index > 0 && index < 4 ? (
                <TextContainer>
                  <Name>{item?.displayName}</Name>
                  <Description>{item?.description}</Description>
                </TextContainer>
              ) : null}
            </FilmmakerCard>
          ))}
        </FilmmakersContainer>
      </HiddenComponent>

      <Footer>
        {data.map((item, index) => (
          <FooterItem key={index} indexNum={index}>
            <FooterTitle>{item.title}</FooterTitle>
            <FooterDescription>{item.description}</FooterDescription>
          </FooterItem>
        ))}
      </Footer>
    </Section>
  );
};

const data = [
  {
    title: 'Sem Limites na TV',
    description:
      'Todos os vídeos são 100% nacionais, criados por talentos brasileiros. Utilize todo nosso acervo na televisão, sem limite de tempo e sem taxa de importação.',
  },
  {
    title: 'Curadoria',
    description:
      'Todas as imagens passam por um processo de seleção estética e técnica e fazem parte de uma sequência. Conte uma história com diversos planos da mesma cena.',
  },
  {
    title: 'Qualidade de cinema',
    description:
      'Teste vídeos em HD e baixe em 4K para deixar seus comerciais com cara de cinema. Diga adeus aquela estética velha de banco de imagens.',
  },
];
