import { createStore } from 'zustand/vanilla';
import { useStore } from 'zustand';
import {
  FilmmakerProfile,
  FilmmakerSale,
  DashboardFilmmakerLicenses,
  FilmmakerCommissionInfo,
} from '@bamboo/core-lib/src/api/types';

export type InitialState = {
  filmmakerBySlug: { [slug: string]: FilmmakerProfile | undefined };
  filmmakersListed: string[];
  filmmakerSales: FilmmakerSale[];
  commissionClips: FilmmakerCommissionInfo[];
  filmmakerLicenses: DashboardFilmmakerLicenses | undefined;
  filmmakerDashboardByID: {
    [filmID: string]: DashboardFilmmakerLicenses | undefined;
  };
  productFilmmakerByID: { [filmID: string]: number[] | undefined };
};

const initialData: InitialState = {
  filmmakerBySlug: {},
  filmmakersListed: [],
  filmmakerSales: [],
  commissionClips: [],
  filmmakerLicenses: undefined,
  filmmakerDashboardByID: {},
  productFilmmakerByID: {},
};

let store = createStore<InitialState>(() => initialData);

export const setFilmmaker = (filmmaker: FilmmakerProfile) => {
  let filmmakerBySlug = store.getState().filmmakerBySlug;
  filmmakerBySlug[filmmaker.slug] = filmmaker;
  store.setState({ filmmakerBySlug });
};

export const setFilmmakers = (
  filmmakers:
    | FilmmakerProfile[]
    | ((p: FilmmakerProfile[]) => FilmmakerProfile[])
) => {
  let list: FilmmakerProfile[] = [];
  let filmmakerBySlug = store.getState().filmmakerBySlug;

  if (Array.isArray(filmmakers)) {
    list = filmmakers;
  } else {
    const current = Object.keys(filmmakerBySlug)
      .map((slug) => filmmakerBySlug[slug])
      .filter((f) => !!f) as FilmmakerProfile[];
    list = filmmakers(current);
  }

  const filmmakersListed = list.map((f) => {
    filmmakerBySlug[f.slug] = f;
    return f.slug;
  });

  store.setState({
    filmmakerBySlug,
    filmmakersListed,
  });
};

export const setProductFilmmakers = (
  filmID: string,
  ids: number[] | ((ids: number[]) => number[])
) => {
  let productFilmmakerByID = store.getState().productFilmmakerByID;
  if (Array.isArray(ids)) {
    productFilmmakerByID[filmID] = ids;
  } else {
    const current = productFilmmakerByID?.[filmID] ?? [];
    productFilmmakerByID[filmID] = ids(current);
  }
  store.setState({ productFilmmakerByID });
};

export const setFilmmakerSales = (sales: FilmmakerSale[]) => {
  store.setState({ filmmakerSales: sales });
};

export const setFilmmakerLicenses = (licenses: DashboardFilmmakerLicenses) => {
  store.setState({ filmmakerLicenses: licenses });
};

export const setFilmmakerDashboards = (list: DashboardFilmmakerLicenses[]) => {
  let filmmakerDashboardByID = store.getState().filmmakerDashboardByID;
  list.forEach((dashboard) => {
    filmmakerDashboardByID[dashboard.filmID] = dashboard;
  });

  store.setState({ filmmakerDashboardByID });
};

export const setCommissionClips = (
  commissionClips: FilmmakerCommissionInfo[]
) => {
  store.setState({ commissionClips });
};

export const setFilmmakerDashboard = (
  dashboard: DashboardFilmmakerLicenses
) => {
  let filmmakerDashboardByID = store.getState().filmmakerDashboardByID;
  filmmakerDashboardByID[dashboard.filmID] = dashboard;
  store.setState({ filmmakerDashboardByID });
};

export function hydrate(initialData: InitialState) {
  store = createStore<InitialState>(() => initialData);
}

export function state() {
  return store.getState();
}

export const useFilmmakers = () => useStore(store, (s) => s);
