import { setPlaylists, useClips } from '@bamboo/core-lib/repo/clips';
import {
  Row,
  Section,
  VerticalLine1,
  VerticalLine2,
  VerticalLine3,
} from './Categories.styles';
import { useTheme } from '@mui/material/styles';
import api from '@bamboo/core-lib/api';
import { useEffect } from 'react';
import { CategoriesCarousel } from './components/CategoriesCarousel';
import { useMediaQuery } from '@mui/material';
import HomeCarousel from '../../HomeCarousel';

export const Categories = () => {
  const { playlists } = useClips();

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  useEffect(() => {
    api
      .playlistList({ queries: { limit: 10 } })
      .then((r) => setPlaylists(r.data.items))
      .catch(console.warn);
  }, []);

  if (playlists.length === 0) return null;

  return (
    <Section>
      {!isSmallerScreen && (
        <>
          <Row>
            <CategoriesCarousel type="1" key={'playlist-1'} />

            <VerticalLine1 />
          </Row>
          <Row>
            <CategoriesCarousel type="2" key={'playlist-2'} />

            <VerticalLine2 />
          </Row>
          <Row>
            <CategoriesCarousel type="3" key={'playlist-3'} />

            <VerticalLine3 />
          </Row>
        </>
      )}

      {isSmallerScreen && <HomeCarousel />}
    </Section>
  );
};
