import {
  Section,
  LeftSide,
  Title,
  Description,
  RightSide,
  StyledImage,
  StyledAncineImage,
  ContainerTvImage,
} from './NoLimits.styles';
import { useRouter } from 'next/navigation';

export const NoLimits = () => {
  const router = useRouter();

  return (
    <Section>
      {/* Left Side */}
      <LeftSide>
        {/* Title */}
        <Title>Aprovado para TV</Title>
        {/* Description */}
        <Description>
          Vá além dos 20% de limite de tela e veicule comerciais criados
          inteiramente com a Bamboo Stock em todas as emissoras do país.
        </Description>
      </LeftSide>

      {/* Right Side */}
      <RightSide>
        <StyledAncineImage
          src="/images/ancine-logo.svg"
          width={130}
          height={94}
          alt="Ancine Logo"
        />

        <ContainerTvImage>
          <StyledImage
            src="/images/sbt.jpg"
            width={75}
            height={75}
            alt="Logo SBT"
          />
          <StyledImage
            src="/images/globo.jpg"
            width={75}
            height={75}
            alt="Logo Globo"
          />
          <StyledImage
            src="/images/record.jpg"
            width={75}
            height={75}
            alt="Logo Record"
          />
        </ContainerTvImage>
      </RightSide>
    </Section>
  );
};
